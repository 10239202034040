import {
  ExclamationCircleOutlined,
  MailOutlined,
  MobileOutlined,
  PrinterOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Typography, notification } from "antd";
import axios from "axios";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import JumbotronComponent from "@components/Jumbotron";
import Layout from "@components/Layout";
import LocationContext from "@components/LocationContext";
import SEO from "@components/SEO";
import { publicIdToLocal } from "@utils/cloudinary";
import { graphql } from 'gatsby';

const { TextArea } = Input;
const { Title } = Typography;

const formName = `contact`;

const ContactFormCSS = styled.div`
  .biel-primary-button {
    background: green;
    border-color: green;
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus {
    border-color: green;
  }

  .ant-input:hover,
  .ant-input:focus {
    border-color: green;
  }
`;

const ContactMainCSS = styled.div`
  width: 70vw;
  margin: 0 auto;
  padding-top: 100px;

  h1 {
    font-size: 3rem;
    font-weight: 800;
  }

  .logo-display {
    background-image: url("/img/logo.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    width: 50vw;
    height: 50vh;

    align-self: center;
    margin-right: 2rem;
  }

  @media (max-width: 1024px) {
    .logo-display {
      display: none;
    }
  }
  .col {
    margin-top:30px;
  }
  .content-title {
    border-bottom:2px solid #8e8e8e;
    width: 80%;
    font-size:20px;
    font-weight:normal
  }
  .content-main {
    width: 80%;
  }
`;

const parseAddress = lines => lines.split("\n").map((line, index) => <p key={index}>{line}</p>);

const NetlifyContactForm = ({ data, location }) => {
  const [submitting, setSubmitting] = useState(false);
  const { t, i18n } = useTranslation();
  const tempContactData = data.locales.edges
  const index = tempContactData.findIndex((value, index) => value.node.ns === "contact-us")
  const contactData = JSON.parse(data.locales.edges[index].node.data).contactDetails

  const handleSubmit = async values => {
    // Honeypot for bot detection
    if (values[`bot-field`] === undefined) {
      delete values[`bot-field`];
    } else {
      return;
    }

    try {
      setSubmitting(true);
      const res = await axios.post("https://1391981163470507.cn-shenzhen.fc.aliyuncs.com/2016-08-15/proxy/biel-landing-page/contact-form/", values);
      showSuccess();
    } catch (e) {
      showError(e);
    } finally {
      setSubmitting(false);
    }
  };

  const content = [
    <>{parseAddress(t("hq-hk"))}{t("hq-cn")}</>,
    <>
      {t("email")} <MailOutlined /> :{" "}
      <a href="mailto:info@bielcrystal.com.hk">info@bielcrystal.com.hk</a>
    </>,
    <>
      {t("office")} <MobileOutlined /> <br />
      {t("hk")}: <a href="tel:+852-23440101"> +852-23440101 </a>
      <br />
      {t("cn")}: <a href="tel:+86-0752-6518888-0"> +86-0752-6518888-0</a>
    </>,
    <>
      {t("fax")} <PrinterOutlined /> :{" "}
      <a href="tel:+852-27978939">(852) 27978939</a>
    </>,
  ];

  const showSuccess = () => {
    notification.open({
      message: t("messageSent"),
      description: t("notificationDescription"),
      icon: <MailOutlined style={{ color: "green" }} />,
    });
  };

  const showError = error => {
    notification.open({
      message: t("messageFailure"),
      description: t("failureDescription"),
      icon: <ExclamationCircleOutlined style={{ color: "green" }} />,
    });
  };

  return (
    <Layout location={location} t={t} i18n={i18n}>
      <SEO title={t("title")} description={t("description")} pathname={location.pathname} />
      <LocationContext.Consumer>
        {props => (
          <>
            <JumbotronComponent
              t={t}
              imgUrl={publicIdToLocal["biel/contact_us_jumbotron_u7nqn7"]}
              isJumbotron
            />
            <ContactMainCSS>
              <Title level={3}>{t('contactWay')}</Title>
              <Row>
                {
                  contactData && contactData.map(res => {
                    return (
                      <Col xs={24} lg={8} xl={8} className="col">
                        <p className="content-title" >{res.title}</p>
                        <div className="content-main">
                          <Title level={5}>{res.address}</Title>
                          <Title level={5}>{res.phoneNumber}</Title>
                          <Title level={5}>{res.email}</Title>
                        </div>
                      </Col>
                    )
                  })
                }
              </Row>

              <ContactFormCSS style={{ marginTop: '40px' }}>
                <Title level={3}>{t('optionCollection')}</Title>
                <form
                  name={formName}
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  hidden
                >
                  <input type="text" name="name" />
                  <input type="email" name="email" />
                  <textarea name="message"></textarea>
                </form>

                <Form
                  name="cf"
                  method="post"
                  onFinish={handleSubmit}
                  layout="vertical"
                >
                  {/* This is the hidden field that the netlify-honeypot uses. */}
                  <Form.Item
                    label="Don't fill this out"
                    className={`hidden`}
                    style={{ display: `none` }}
                    name="bot-field"
                  >
                    <Input type={`hidden`} />
                  </Form.Item>

                  <Form.Item
                    label={t("name")}
                    rules={[{ required: true, message: t("nameAlert") }]}
                    name="name"
                  >
                    <Input
                      placeholder={t("your-name")}
                      prefix={<UserOutlined className="site-form-item-icon" />}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("email")}
                    rules={[
                      {
                        required: true,
                        type: `email`,
                        message: t("emailAlert"),
                      },
                    ]}
                    name="email"
                  >
                    <Input
                      placeholder={t("your-email")}
                      prefix={<MailOutlined className="site-form-item-icon" />}
                    />
                  </Form.Item>
                  <Form.Item label={t("subject")} name="subject">
                    <Input placeholder={t("subject-optional")} />
                  </Form.Item>
                  <Form.Item
                    label={t("message")}
                    rules={[{ required: true, message: t("messageAlert") }]}
                    name="message"
                  >
                    <TextArea placeholder={t("your-message")} rows={10} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="biel-primary-button"
                      type="primary"
                      htmlType="submit"
                      disabled={false}
                      loading={submitting}
                    >
                      {t("send")}
                    </Button>
                  </Form.Item>
                </Form>
              </ContactFormCSS>
              <div style={{ fontSize: "1rem", textAlign: 'center' }}>{t("intro")}</div>
            </ContactMainCSS>
          </>
        )}
      </LocationContext.Consumer>
    </Layout>
  );
};

NetlifyContactForm.propTypes = {
  location: PropTypes.object.isRequired,
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {ns: {in: ["contact-us","footer"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default NetlifyContactForm;
